export default function FooterLinks() {
  return (
    <div className="flex gap-x-32 *:flex *:flex-col *:items-center lg:*:items-start *:gap-y-5 *:text-slate-400 *:text-sm *:cursor-pointer">
      <div className="*:transition">
        <h5 className="text-lg cursor-default text-lightBg">خدمات</h5>
        <span className="hover:opacity-50">جستجوی پزشک</span>
        <span className="hover:opacity-50">نوبت دهی آنلاین</span>
        <span className="hover:opacity-50">مشاوره پزشکی</span>
      </div>
      <div className="*:transition">
        <h5 className="text-lg cursor-default text-lightBg">ارتباط با ما</h5>
        <span className="hover:opacity-50">درباره ما</span>
        <span className="hover:opacity-50">تماس با ما</span>
        <span className="hover:opacity-50">سوالات متداول</span>
      </div>
    </div>
  );
}
