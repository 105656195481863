import FooterContent from "./components/Footer/FooterContent";
import DoctorSearchBox from "./components/Header/DoctorSearchBox";
import HeaderBg from "./components/Header/HeaderBg";
import HeaderMainSection from "./components/Header/HeaderMainSection";
import Navbar from "./components/Header/Navbar";
import BestDoctorsSection from "./components/Main/BestDoctorsSection";
import OurServiceSection from "./components/Main/OurServiceSection";

function App() {
  return (
    <>
      <header className="relative flex flex-col items-center justify-between w-screen h-full gap-y-20 md:gap-0 md:h-screen">
        <HeaderBg />
        <Navbar />
        <HeaderMainSection />
        <DoctorSearchBox />
      </header>

      <main>
        <BestDoctorsSection />
        <OurServiceSection />
      </main>

      <footer>
        <FooterContent />
      </footer>
    </>
  );
}

export default App;
