export default function BestDoctorsSection() {
  return (
    <section className="flex items-center justify-center w-full h-screen">
      <article className="flex justify-between items-center flex-col-reverse lg:flex-row w-full md:w-[83%] gap-10 lg:gap-0">
        <div className="w-full px-10 md:p-0 lg:w-[38%] flex justify-center items-center lg:items-start gap-y-10 flex-col">
          <div className="space-y-5">
            <h2 className="text-center lg:text-justify text-lg lg:text-xl xl:text-3xl !leading-[3rem] md:!leading-[3rem] font-iranSansBold">
              بهترین پزشکان ، کلینیک و بیمارستان را که نزدیک به شما است کشف کنید.
            </h2>
            <p className="text-[15px] text-justify text-textLight">
              شما می‌توانید از میان بهترین پزشکان مختصص، پزشک مورد نظر را انتخاب کرده، هرگونه مشکل یا سوال مربوط به بیماری خود را به صورت حضوری یا متنی یا صوتی مطرح کنید، به صورت آنلاین با پزشک گفتگو
              کنید و در وقت و هزینه خود صرفه جویی کنید.
            </p>
          </div>
          <button type="submit" className="flex items-center gap-2 btn">
            <span className="text-sm lg:text-base xl:text-lg">رزرو نوبت پزشک</span>
            <img className="size-4 lg:size-6" src="/images/main/whatsapp.svg" alt="whatsapp icon" />
          </button>
        </div>
        <div className="flex justify-end  w-[57.5%] *:object-cover">
          <img src="/images/main/best__doctors__section__img.png" alt="Best Doctors Section img" />
        </div>
      </article>
    </section>
  );
}
