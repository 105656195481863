import { useState } from "react";

// Header Navbar
export default function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <nav className="flex items-center justify-between w-full p-6 select-none  md:py-8 xl:py-12 md:px-5 xl:px-10">
      {/* Ham Icon */}
      <button className="z-50 md:hidden" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-black size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        )}
      </button>

      {/* Navbar Logo and links  */}
      <div className="flex items-center gap-x-4 md:gap-x-6 lg:gap-x-8 xl:gap-x-12">
        <div className="flex items-center">
          <img className="w-6 h-6 md:h-8 md:w-8 xl:h-12 xl:w-12" src="/images/header/ic_sharp-health-and-safety.svg" alt="Navbar Icon" />
          <span className="text-sm font-bold lg:text-base xl:text-lg">سامانه نوبت دهی پزشکیار</span>
        </div>

        {/* Mobile Responsive Navbar */}
        <div
          className={`z-40 md:hidden fixed top-0 right-0 grid place-content-center w-full h-fit gradiant__bg p-10 pt-16 rounded-b-3xl shadow-2xl transform transition-transform ${
            isSidebarOpen ? "translate-y-0" : "-translate-y-full"
          } *:text-center *:text-white`}
        >
          <ul className="space-y-4 *:text-sm hover:*:text-lighBlue *:transition-colors">
            <li>
              <a href="#1" onClick={() => setIsSidebarOpen(false)}>
                نوبت دهی مطب
              </a>
            </li>
            <li>
              <a href="#2" onClick={() => setIsSidebarOpen(false)}>
                تخصص ها
              </a>
            </li>
            <li>
              <a href="#3" onClick={() => setIsSidebarOpen(false)}>
                جستجوی پزشک
              </a>
            </li>
            <li>
              <a href="#4" onClick={() => setIsSidebarOpen(false)}>
                ارتباط با ما
              </a>
            </li>
          </ul>
        </div>

        {/* Desktop Responsive Navbar */}
        <ul className="hidden md:flex items-center md:gap-x-4 lg:gap-x-6 xl:gap-x-10 md:*:text-sm lg:*:text-sm xl:*:text-base hover:*:text-lighBlue *:transition-colors ">
          <li>
            <a href="#1">نوبت دهی مطب</a>
          </li>
          <li>
            <a href="#2">تخصص ها</a>
          </li>
          <li>
            <a href="#3">جستجوی پزشک</a>
          </li>
          <li>
            <a href="#4">ارتباط با ما</a>
          </li>
        </ul>
      </div>

      {/* Navbar Login & Signup btn  */}
      <div className="md:pl-10">
        <button className="btn">ورود / ثبت نام</button>
      </div>

      {/* Mobile Navbar background filter */}
      <div className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${isSidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`} onClick={() => setIsSidebarOpen(false)}></div>
    </nav>
  );
}
