import { useState } from "react";

export default function DoctorSearchBox() {
  const [selectExperience, setSelectExperience] = useState("");
  const [selectDoctor, setSelectDoctor] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex justify-center w-11/12 lg:w-[83%] m-10 lg:mx-0 rounded-2xl gradiant__bg py-8 select-none">
      <form className="flex flex-col md:flex-row items-center justify-between gap-5 md:gap-0 w-[88.4%] rounded-xl bg-white p-4">
        <div className="flex flex-col md:flex-row justify-between gap-5 md:gap-2 w-[75%] text-textLight *:text-xs lg:*:text-sm xl:*:text-base">
          <select id="select__experience" value={selectExperience} onChange={(e) => setSelectExperience(e.target.value)} className="outline-none cursor-pointer aria-checked:cursor-pointer">
            <option value="" disabled>
              انتخاب تخصص
            </option>
            <option value="option1">گزینه 1</option>
            <option value="option2">گزینه 2</option>
            <option value="option3">گزینه 3</option>
          </select>

          <select id="select__doctor" value={selectDoctor} onChange={(e) => setSelectDoctor(e.target.value)} className="outline-none cursor-pointer aria-checked:cursor-pointer">
            <option value="" disabled>
              انتخاب پزشک، متخصص
            </option>
            <option value="option1">گزینه 1</option>
            <option value="option2">گزینه 2</option>
            <option value="option3">گزینه 3</option>
          </select>

          <label className="inline-flex items-center cursor-pointer gap-x-2">
            <input
              type="radio"
              name="radio-options"
              value="option1"
              checked={selectedOption === "option1"}
              onChange={handleChange}
              className="w-4 h-4 text-blue-600 border-gray-300 cursor-pointer indeterminate:bg-gray-300"
            />
            <span>نوبت حضوری</span>
          </label>

          <label className="inline-flex items-center cursor-pointer gap-x-2">
            <input
              type="radio"
              name="radio-options"
              value="option2"
              checked={selectedOption === "option2"}
              onChange={handleChange}
              className="w-4 h-4 text-blue-600 border-gray-300 cursor-pointer focus:ring-blue-500"
            />
            <span>مشاوره آنلاین</span>
          </label>
        </div>

        <button type="submit" className="flex items-center gap-2 btn">
          <span className="text-xs lg:text-sm xl:text-lg">جستجوی پزشک</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 lg:size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
        </button>
      </form>
    </div>
  );
}
