export default function FooterDetail() {
  return (
    <div className="flex flex-col items-center w-full lg:w-auto lg:items-end gap-y-5">
      <div className="flex items-center gap-x-2">
        <img className="size-6 md:size-7 xl:size-8 " src="/images/footer/ic_sharp-health-and-safety__footer.svg" alt="medical Icon" />
        <span className="text-sm md:text-base lg:text-base xl:text-lg text-lightBg">سامانه نوبت دهی پزشکیار</span>
      </div>
      <p className="text-sm text-lightBg/60 ">مشهد، دانشجو 8، کلینیک پاوش</p>
    </div>
  );
}
