export default function FooterContactUs() {
  return (
    <div className="flex items-center h-full gap-2 pt-10 md:gap-5 lg:items-end lg:flex-col text-slate-400">
      <span className="text-sm">06265644</span>
      <span className="text-sm">info@pyar.com</span>
      <div className="flex gap-2 md:gap-5 *:cursor-pointer hover:*:opacity-50 *:transition">
        <img src="/images/footer/whatsapp.svg" alt="whatsapp icon" />
        <img src="/images/footer/facebook.svg" alt="facebook icon" />
        <img src="/images/footer/youtube.svg" alt="youtube icon" />
      </div>
    </div>
  );
}
