export default function HeaderMainSection() {
  return (
    <section className="flex items-center justify-center w-full">
      <article className="flex flex-col-reverse md:flex-row justify-between gap-y-10 md:gap-y-0 px-14 md:p-0 md:w-[73.4%]">
        <div className="flex flex-col justify-evenly items-center md:items-start space-y-5 md:w-[54.6%] xl:w-5/12">
          <span className="text-center md:text-right text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl !leading-[5rem] font-iranSansBold">سامانه نوبت دهی پزشکان و مشاوره آنلاین</span>
          <button className="flex px-4 py-3 transition bg-white rounded shadow-lg gap-x-2 w-fit text-lighBlue shadow-lighBlue/30 hover:shadow-lighBlue/60">
            <span className="text-sm lg:text-base">لیست تخصص های پزشکی</span>
            <img className="size-3 md:size-4 lg:size-5 xl:size-6" src="/images/header/pasient.svg" alt="Pasient Svg" />
          </button>
        </div>

        <div className="grid overflow-hidden place-content-center">
          <img className="object-cover rounded-full size-64 md:size-56 lg:size-64 xl:size-80 2xl:size-96" src="/images/header/landing__header__main__img.svg" alt="Landing_Header_Image" />
        </div>
      </article>
    </section>
  );
}
