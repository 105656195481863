export default function MedicalMembershipSection() {
  return (
    <section className=" flex justify-start items-center flex-col w-full h-full py-32 px-8 md:px-0 md:w-[83%]">
      <div className="relative flex flex-col items-center justify-center w-full px-5 py-20 space-y-5 bg-white shadow-xl md:space-y-10 xl:px-48 rounded-3xl">
        <div className="absolute top-0 left-0 -translate-y-1/2 size-28 md:size-32 xl:size-40">
          <img src="/images/main/medical__membership__section__shape.svg" alt="medical membership section shape" />
        </div>

        <h3 className="text-xl text-center lg:text-3xl font-iranSansBold">درخواست عضویت پزشکان</h3>
        <p className="text-sm text-center md:text-base text-textDark">
          پزشکان گرامی، شما می‌توانید با عضویت در سایت، از تمامی سرویس‌ها و خدمات سامانه ما بهرمند شوید. همچنین کسب‌و‌کار آنلاینتان را رشد و بهبود دهید.
        </p>
        <button className="w-fit btn">درخواست عضویت</button>
      </div>
    </section>
  );
}
