export default function OurServiceSection() {
  return (
    <section className="relative flex items-start justify-center w-full h-auto md:h-screen">
      <article className=" flex justify-start items-center flex-col w-full h-full md:w-[83%] gap-y-20 md:gap-y-40">
        <div className="absolute right-0 -translate-y-1/2 top-1/2 -z-50">
          <img src="/images/main/our__serivce__bg__shape.svg" alt="our serivce section bg" />
        </div>

        <div>
          <h3 className="text-4xl font-iranSansExtraBold blue__text__gradiant">خدمات ما</h3>
        </div>

        <div className="grid place-content-center justify-center grid-cols-1 px-8 md:px-0  md:grid-cols-3 gap-8 md:gap-4 lg:gap-16 xl:gap-20 2xl:gap-36 *:flex *:flex-col *:justify-start *:items-center *:px-8 *:space-y-8 md:*:space-y-8 lg:*:space-y-14 *:pb-10 *:pt-20 *:rounded-3xl  *:cursor-pointer hover:*:shadow-2xl *:transition *:bg-lightBg even:*:blue__gradiant__bg">
          <div>
            <span className="our__service__item__img">
              <img src="/images/main/our__serivce__first__item.svg" alt="our serivce first item" />
            </span>

            <span className="space-y-2 text-black md:space-y-2 lg:space-y-4 xl:space-y-6">
              <h3 className="text-base text-center transition cursor-pointer lg:text-lg xl:text-2xl md:text-right font-iranSansBold hover:opacity-50 ">نوبت‌دهی آنلاین پزشکان</h3>
              <p className="text-sm leading-5 text-justify xl:leading-7">
                به صورت 24 ساعته از پزشکان متخصص و با تجربه نوبت اینترنتی دریافت کنید. اولین نوبت خالی برای ملاقات حضوری با پزشک را به راحتی دریافت کنند.
              </p>
            </span>
          </div>

          <div>
            <span className="our__service__item__img">
              <img src="/images/main/our__serivce__second__item.svg" alt="our serivce second item" />
            </span>

            <span className="space-y-2 md:space-y-2 text-lightBg lg:space-y-4 xl:space-y-6">
              <h3 className="text-base text-center transition cursor-pointer lg:text-lg xl:text-2xl md:text-right font-iranSansBold hover:opacity-50 ">مشاوره تلفنی با پزشک</h3>
              <p className="text-sm leading-5 text-justify xl:leading-7">
                برای مشاوره گرفتن از پزشک نیازی به مراجعه به مطب نیست، پزشک با شما تماس می‌گیرد و یا می توانید از مشاوره متنی بهرمند شوید و برای پزشک سوال خود را در پیام رسان ارسال کنید.
              </p>
            </span>
          </div>

          <div>
            <span className="our__service__item__img">
              <img src="/images/main/our__serivce__third__item.svg" alt="our serivce third item" />
            </span>

            <span className="space-y-2 text-black md:space-y-2 lg:space-y-4 xl:space-y-6">
              <h3 className="text-base text-center transition cursor-pointer lg:text-lg xl:text-2xl md:text-right font-iranSansBold hover:opacity-50 ">صرفه جویی در زمان و هزینه</h3>
              <p className="text-sm leading-5 text-justify xl:leading-7">
                در سریع ترین زمان ممکن میتوانید از پزشک مورد نظر خود وقت ملاقات دریافت کنید و از ساعت فعالیت پزشکان در کلینیک اطلاعات کسب کنید.
              </p>
            </span>
          </div>
        </div>
      </article>
    </section>
  );
}
