import MedicalMembershipSection from "../Main/MedicalMembershipSection";
import FooterContactUs from "./FooterContactUs";
import FooterDetail from "./FooterDetail";
import FooterLinks from "./FooterLinks";

export default function FooterContent() {
  return (
    <article className="relative flex flex-col items-center justify-start w-full h-auto mt-20 overflow-hidden md:gap-y-10">
      <div className="absolute top-0 right-0 w-full lg:w-[66%]  *:size-full *:object-cover -z-50">
        <img src="/images/main/medical__membership__section__bg.svg" alt="medical membership section shape" />
      </div>

      <MedicalMembershipSection />

      <div className="flex flex-col items-center justify-center w-full px-5  py-10 md:px-40  bg-[#0957DE] ">
        <div className="flex flex-col items-center justify-between w-full pb-8 space-y-10 border-b lg:items-start lg:space-y-0 lg:flex-row border-lightBg/80">
          <FooterDetail />
          <FooterLinks />
          <FooterContactUs />
        </div>
      </div>
    </article>
  );
}
